exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-amenti-solar-pv-research-cisbat-2023-js": () => import("./../../../src/pages/blog/amenti-solar-pv-research-cisbat-2023.js" /* webpackChunkName: "component---src-pages-blog-amenti-solar-pv-research-cisbat-2023-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact-form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-demo-video-js": () => import("./../../../src/pages/demo-video.js" /* webpackChunkName: "component---src-pages-demo-video-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-data-js": () => import("./../../../src/pages/join-data.js" /* webpackChunkName: "component---src-pages-join-data-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-latest-index-js": () => import("./../../../src/pages/latest/index.js" /* webpackChunkName: "component---src-pages-latest-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-app-js": () => import("./../../../src/pages/products/app.js" /* webpackChunkName: "component---src-pages-products-app-js" */),
  "component---src-pages-products-data-js": () => import("./../../../src/pages/products/data.js" /* webpackChunkName: "component---src-pages-products-data-js" */),
  "component---src-pages-products-service-js": () => import("./../../../src/pages/products/service.js" /* webpackChunkName: "component---src-pages-products-service-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

