import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '../../theme';

const LayoutWrapper = (props) => (
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  </>
);

export default LayoutWrapper;
