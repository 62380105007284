import { alpha, createTheme, responsiveFontSizes } from '@mui/material';

import RegularWoff from './assets/fonts/MonumentGrotesk-Regular.woff';
import RegularWoff2 from './assets/fonts/MonumentGrotesk-Regular.woff2';

import ItalicWoff from './assets/fonts/MonumentGrotesk-Italic.woff';
import ItalicWoff2 from './assets/fonts/MonumentGrotesk-Italic.woff2';

import MediumWoff from './assets/fonts/MonumentGrotesk-Medium.woff';
import MediumWoff2 from './assets/fonts/MonumentGrotesk-Medium.woff2';

import MediumItalicWoff from './assets/fonts/MonumentGrotesk-MediumItalic.woff';
import MediumItalicWoff2 from './assets/fonts/MonumentGrotesk-MediumItalic.woff2';

import BoldItalicWoff from './assets/fonts/MonumentGrotesk-BoldItalic.woff';
import BoldItalicWoff2 from './assets/fonts/MonumentGrotesk-BoldItalic.woff2';

import BoldWoff from './assets/fonts/MonumentGrotesk-Bold.woff';
import BoldWoff2 from './assets/fonts/MonumentGrotesk-Bold.woff2';

let theme = createTheme({
  mode: 'light',
  palette: {
    background: {
      default: '#030B0E',
    },
    common: {
      black: '#030B0E',
      white: '#FBFBFB',
    },
    primary: {
      main: '#030B0E',
      light: '#9D9FA0',
      dark: '#030B0E',
      contrastText: '#FBFBFB',
    },
    secondary: {
      main: '#1FC676',
      light: '#AEEACE',
      dark: '#127646',
      contrastText: '#FBFBFB',
    },
    tertiary: {
      main: '#3B5EB1',
      light: '#3B5EB1',
      dark: '#082B7E',
      contrastText: '#FBFBFB',
    },
    success: {
      main: '#1FC676',
      light: '#AEEACE',
      dark: '#127646',
      contrastText: '#FBFBFB',
    },
    error: {
      main: '#E40E00',
      light: '#e93e33',
      dark: '#9f0900',
      contrastText: '#FBFBFB',
    },
    white: {
      main: '#FBFBFB',
      light: '#FFFFFF',
      dark: '#CBD2D4',
      contrastText: '#1b2122',
    },
    green: {
      100: '#EDFBF4',
      200: '#DCF6E9',
      300: '#AEEACE',
      400: '#4CD191',
      500: '#1FC676',
      600: '#199E5E',
      700: '#127646',
      800: '#0F5F38',
      900: '#0B472B',
    },
    blue: {
      100: '#ECEFF7',
      200: '#D9DFF0',
      300: '#6B86C4',
      400: '#3B5EB1',
      500: '#0A369D',
      600: '#082B7E',
      700: '#06205E',
      800: '#051A4B',
      900: '#041339',
    },
    teal: {
      100: '#E8EBEC',
      200: '#CBD2D4',
      300: '#A7B4B6',
      400: '#8B9C9F',
      500: '#6E8387',
      600: '#58696C',
      700: '#424E50',
      800: '#353F41',
      900: '#282F31',
    },
    grey: {
      50: '#ffffff',
      100: '#fbfbfb',
      200: '#ebebeb',
      300: '#dededf',
      400: '#bebfc0',
      500: '#9d9fa0',
      600: '#7d8081',
      700: '#5c6061',
      800: '#3b4142',
      900: '#1b2122',
      A100: '#fbfbfb',
      A200: '#ebebeb',
      A400: '#bebfc0',
      A700: '#5c6061',
    },
    yellow: '#FCE694',
    building: {
      attic: '#a3bae0',
      fullFloors: '#456DCF',
      firstFloor: '#3030C5',
      underground: '#2D469F',
      multiFamily: '#598AD9',
    },
  },
  typography: {
    fontFamily: 'Monument Grotesk, sans-serif',
  },
});

theme = createTheme(theme, {
  text: {
    primary: '#1b2122',
    secondary: '#5c6061',
    disabled: 'rgba(92,96,97,0.4)',
  },
  divider: '#dededf',
  background: {
    paper: '#fbfbfb',
    default: '#fbfbfb',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${RegularWoff2}) format('woff2'),
          url(${RegularWoff}) format('woff');
          font-weight: 400;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${ItalicWoff2}) format('woff2'),
          url(${ItalicWoff}) format('woff');
          font-weight: 400;
          font-style: italic;
        }
        
        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${MediumWoff2}) format('woff2'),
          url(${MediumWoff}) format('woff');
          font-weight: 500;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${MediumItalicWoff2}) format('woff2'),
          url(${MediumItalicWoff}) format('woff');
          font-weight: 500;
          font-style: italic;
        }
        
        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${BoldWoff2}) format('woff2'),
          url(${BoldWoff}) format('woff');
          font-weight: 700;
          font-style: normal;
        }
        
        @font-face {
          font-family: 'Monument Grotesk';
          src: url(${BoldItalicWoff2}) format('woff2'),
          url(${BoldItalicWoff}) format('woff');
          font-weight: 700;
          font-style: italic;
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 100,
          textTransform: 'none',
          boxShadow: 'none',
        },
        sizeLarge: {
          fontSize: 20,
        },
        containedSecondary: {
          '&.Mui-disabled': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.25),
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& strong': {
            fontWeight: 'inherit',
            color: theme.palette.green[500],
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
